<template>
  <q-form ref="editForm">
    <c-card title="재해상황 및 사고발생 정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 저장 -->
          <c-btn
            v-if="!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="tabParam"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고명 -->
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="LBL0002306"
            name="accidentName"
            v-model="tabParam.accidentName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고번호 -->
          <c-text
            :editable="editable"
            :disabled="true"
            label="LBL0002305"
            name="accidentNo"
            v-model="tabParam.accidentNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-multi-select
            required
            codeGroupCd="IIM_KIND_CD"
            :editable="editable"
            :disabled="disabled"
            :isArray="false"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="accidentKindCd"
            label="재해유형"
            v-model="tabParam.accidentKindCd"
          >
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="tabParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고발생부서/보고자 -->
          <c-field
            required
            :editable="editable"
            :disabled="disabled"
            :data="tabParam"
            label="사고발생부서/보고자"
            type="dept_user"
            deptValue="occurrenceDeptCd"
            name="reportUserId"
            v-model="tabParam.reportUserId">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 보고일시 -->
          <c-datepicker
            required
            :editable="editable"
            :disabled="disabled"
            label="보고일"
            :minuteStep="10"
            name="reportDate"
            v-model="tabParam.reportDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="안전사고의 분류"
            name="accidentType"
            v-model="tabParam.accidentType">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 기타 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="기타"
            name="accidentEtc"
            v-model="tabParam.accidentEtc">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-card title="사고상황" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            :editable="editable"
            type="datetime"
            :disabled="disabled"
            :minuteStep="10"
            label="사고일시"
            name="occurrenceDate"
            v-model="tabParam.occurrenceDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 공정 -->
          <c-process
            :required="true"
            :editable="editable"
            :disabled="disabled"
            label="LBLPROCESS"
            name="processCd"
            v-model="tabParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 작업장소 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="작업장소"
            name="occurrenceLocation"
            v-model="tabParam.occurrenceLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="IIM_MACHINE_CD"
            type="edit"
            :editable="editable"
            :disabled="disabled"
            itemText="codeName"
            itemValue="code"
            name="accidentMachineCd"
            label="기계 가동"
            v-model="tabParam.accidentMachineCd"
          >
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="사고 기인물"
            name="openning"
            v-model="tabParam.openning">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 목격자 -->
          <c-field
            :editable="editable"
            :disabled="disabled"
            label="목격자"
            :data="tabParam"
            deptValue="witnessDeptCd"
            type="dept_user"
            name="witnessUserId"
            v-model="tabParam.witnessUserId">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 사고경위 -->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :isCheck="true"
            :rows="8"
            label="사고경위"
            name="overviewAccidentSituation"
            v-model="tabParam.overviewAccidentSituation"
            @checkboxClick="checkboxClick"
            >
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 사고당시 조치상황 -->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="8"
            label="사고당시 조치상황"
            name="emergencyMeasures"
            v-model="tabParam.emergencyMeasures">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <!-- 인적피해일 시 사고자 정보를 입력하세요. -->
      <c-table
        ref="table"
        title="사고자 정보"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="tabParam.accidentVictimModelList"
        :filtering="false"
        :columnSetting="false"
        :checkClickFlag="false"
        :usePaging="false"
        :editable="editable && !disabled"
        :noDataLabel="$message('MSG0000702')"
        selection="multiple"
        rowKey="victimId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn v-if="editable && !disabled" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
            <!-- 제외 -->
            <c-btn v-if="editable && !disabled && tabParam.accidentVictimModelList.length > 0" :showLoading="false"  label="LBLEXCEPT" icon="remove" @btnClicked="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import { uid } from 'quasar'
export default {
  name: 'process-register-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        accidentTypeCd: '',
        plantCd: '',
        accidentNo: '',
        accidentName: '',
        accidentStatusCd: '',
        witnessUserId: '',
        witnessDeptCd: '',
        reportUserId: '',
        reportDate: '',
        accidentKindCd: '',
        accidentGradeCd: null,
        occurrenceDate: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: 'WC000001',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        humanDamage: 'Y',
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        materialDamageHistory: '',
        humanDamageHistory: '',
        processCd: '',
        toxicFlag: 'N',
        accidentType: '',
        accidentEtc: '',
        accidentMachineCd: null,
        damageClassCd: null,
        woundCd: null,

        accidentVictimModelList: [],
        deleteAccidentVictimModelList: [], // 사고자 삭제 모델:
      }),
    },
  },
  data() {
    return {
      editable: true,
      searchUrl: '',
      grid: {
        columns: [
          {
            name: 'victimName',
            field: 'victimName',
            // 이름
            label: 'LBLNAME',
            style: 'width:80px',
            align: 'center',
            sortable: false
          },
          {
            name: 'age',
            field: 'age',
            label: '연령',
            style: 'width:40px',
            align: 'right',
            sortable: false
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            // 생년월일
            label: 'LBL0002341',
            style: 'width:80px',
            align: 'center',
            sortable: false
          },
          {
            name: 'sexName',
            field: 'sexName',
            label: '성별',
            style: 'width:40px',
            align: 'center',
            sortable: false
          },
          {
            name: 'mobileNo',
            field: 'mobileNo',
            label: '전화',
            style: 'width:90px',
            align: 'center',
            sortable: false
          },
          {
            name: 'enterDate',
            field: 'enterDate',
            label: '입사일자',
            style: 'width:90px',
            align: 'center',
            sortable: false
          },
          {
            name: 'empNo',
            field: 'empNo',
            // 사번
            label: '사번',
            style: 'width:60px',
            align: 'center',
            sortable: false
          },
          {
            name: 'longevityCount',
            field: 'longevityCount',
            label: '근속년수',
            style: 'width:90px',
            align: 'center',
            sortable: false
          },
          {
            name: 'degreeDamage',
            field: 'degreeDamage',
            // 상해정도
            label: '상해정도',
            style: 'width:120px',
            type: "textarea",
            align: 'left',
            sortable: false
          },
          {
            name: 'expectTreatmentDate',
            field: 'expectTreatmentDate',
            label: '추정근로손실일',
            type: "number",
            range: true,
            style: 'width:70px',
            align: 'right',
            sortable: false
          },
          {
            name: 'actionMatters',
            field: 'actionMatters',
            // 담당업무
            label: '담당업무',
            style: 'width:120px',
            type: "textarea",
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '270px'
      },
      saveUrl: '',
      saveType: 'POST',
      isSave: false,
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.saveUrl = transactionConfig.sop.iim.accident.process.insert.url
      this.insertUrl = transactionConfig.sop.iim.accident.process.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.process.update.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.delete.url;
    },
    saveAccident() {
      if (this.popupParam.iimAccidentId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (!this.popupParam.iimAccidentId) this.tabParam.accidentStatusCd = 'ISPC000001'
              this.tabParam.regUserId = this.$store.getters.user.userId
              this.tabParam.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.iimAccidentId = result.data;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', result.data)
    },
    checkboxClick(checkValue) {
      if (checkValue === 'O') {
        this.tabParam.overviewAccidentSituation = 
          '- ' +
          '누가 : \n' + // 누가
          '- ' +
          '누구에게 : \n' + // 누구에게
          '- ' +
          '언제 : \n' + // 언제
          '- ' +
          '어디서 : \n' + // 어디서
          '- ' +
          '무엇을 : \n' + // 무엇을
          '- ' +
          '어떻게 : \n' + // 어떻게
          '- ' +
          '왜 : ';
      } else {
        this.tabParam.overviewAccidentSituation = '';
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    add() {
      this.popupOptions.title = 'LBL0002346'; // 사고자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.accidentVictimModelList, {
            victimUserId: _item.userId,
          });
          if (index === -1) {
            this.tabParam.accidentVictimModelList.splice(0, 0, {
              iimAccidentId: this.tabParam.iimAccidentId,
              victimId: uid(),
              victimUserId: _item.userId,
              victimName: _item.userName,
              birthDate: _item.birthDate,
              mobileNo: _item.mobileNo,
              empNo: _item.empNo,
              enterDate: _item.enterDate,
              longevityCount: _item.longevityCount,
              age: _item.age,
              sexName: _item.sexName,
              degreeDamage: '',
              expectTreatmentDate: '',
              expectTreatmentStartDate: '',
              expectTreatmentEndDate: '',
              actionMatters: '',
              editFlag: 'C'
            })
          }
        })
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.tabParam.accidentVictimModelList = this.$_.reject(this.tabParam.accidentVictimModelList, { victimId: item.victimId })
          if (!this.tabParam.deleteAccidentVictimModelList) {
              this.tabParam.deleteAccidentVictimModelList = []
          }
          if (this.$_.findIndex(this.tabParam.deleteAccidentVictimModelList, { victimId: item.victimId }) === -1
          && item.editFlag !== 'C') {
            this.tabParam.deleteAccidentVictimModelList.push(item)
          }
          this.tabParam.accidentVictimModelList = this.$_.reject(this.tabParam.accidentVictimModelList, { victimId: item.victimId })
        })
      }
    },
  }
};
</script>
